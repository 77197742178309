import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static targets = ['noCouplingCardConfirmation', 'noCouplingCardConfirmed', 'noCouplingCardConfirmedInput', 'detentionPlaceCodeInput', 'noDetentionPlaceCode', 'withDetentionPlaceCode', 'noDetentionPlaceCodeInput', 'detentionPlaceSelection', 'detentionPlaceDataWrapper', 'detentionPlaceData', 'holdingPlaceData', 'isDetentionPlaceOperator', 'stationedInBelgiumSwitch', 'outsideBelgiumHoldingPlace', 'insideBelgiumHoldingPlace', 'birthDate', 'detentionSince', 'submitButton'];

  // detentionPlaceInvalidCodeHandler(event) {
  //   $(this.submitButtonTarget).attr('disabled', 'disabled')
  // }

  // detentionPlaceValidCodeHandler(event) {
  //   $(this.submitButtonTarget).removeAttr('disabled')
  // }

  connect() {
    // if (this.hasDetentionPlaceCodeInputTarget && this.hasSubmitButtonTarget) {
    //   document.addEventListener("detention-place-valid-code", this.detentionPlaceValidCodeHandler.bind(this))
    //   document.addEventListener("detention-place-invalid-code", this.detentionPlaceInvalidCodeHandler.bind(this))
    // }

    let crtl = this
    $(this.element).on("change", "input, textearea, select", function(e){crtl._onInputChange(e)})

    // Trigger du change pous les elements qui ont une valeur
    $(this.element).find("input, texteare, select").each(function(e){
      if ($(this).val().length > 1) {
        $(this).trigger("change")
      }
    })

    // Trigger de "Le produit est-il stationné à l’adresse du détenteur ?" (true ou false)
    $("#birth_registration_horse_stationed_to_first_holder_true").trigger("change")

    $(this.element).on("repeatable.add", ".repeatable", function(e){crtl._onRowRepeat(e)})
    $(this.element).on("repeatable.remove", ".repeatable", function(e){crtl._onRowRemove(e)})

    this.showCardQuestions()
    this.showCardSelection()
    this.showCardSelectionYear()

    if (this.hasNoDetentionPlaceCodeTarget) {
      $(this.noDetentionPlaceCodeTarget).find('a').first().on('click', function(e) {
        e.preventDefault()
        $(crtl.noDetentionPlaceCodeInputTarget).val("true").removeAttr('disabled')
        $(crtl.noDetentionPlaceCodeInputTarget).trigger("change")
      })
    }

    if (this.hasWithDetentionPlaceCodeTarget) {
      $(this.withDetentionPlaceCodeTarget).find('a').first().on('click', function(e) {
        e.preventDefault()
        $(crtl.noDetentionPlaceCodeInputTarget).val("false").removeAttr('disabled')
        $(crtl.noDetentionPlaceCodeInputTarget).trigger("change")
        $(crtl.detentionPlaceCodeInputTarget).removeAttr('disabled')
      })
    }

    if (this.hasBirthDateTarget && this.hasDetentionSinceTarget) {
      $(this.birthDateTarget).on('change', function(e) {
        if ($(this).val() != '' && $(crtl.detentionSinceTarget).val() == '') {
          $(crtl.detentionSinceTarget).pickadate('picker').set('select', $(this).val(), { format: I18n.t("date.formats.mat_datepicker") })
        }
      })
      $(this.birthDateTarget).trigger("change")
    }

    if (this.hasNoDetentionPlaceCodeInputTarget) {
      $(this.noDetentionPlaceCodeInputTarget).on('change', function(e) {
        if ($(this).val() == "true") {
          $(crtl.detentionPlaceSelectionTarget).addClass("d-none").find('input, select').attr('disabled', 'disabled')
          
          $(crtl.holdingPlaceDataTarget).find('input, select').attr('disabled', 'disabled')
          $(crtl.detentionPlaceDataTarget).find('input, select').attr('disabled', 'disabled')
          $(crtl.detentionPlaceDataWrapperTarget).removeClass("d-none")

          $(crtl.isDetentionPlaceOperatorTargets).each(function() {
            if ($(this).is(":checked")) {
              $(this).trigger("change")
            }
          })
        } else if ($(this).val() == "false") {
          $(crtl.detentionPlaceSelectionTarget).removeClass("d-none").find('input, select').removeAttr('disabled')
          
          $(crtl.holdingPlaceDataTarget).find('input, select').attr('disabled', 'disabled')
          $(crtl.detentionPlaceDataTarget).find('input, select').attr('disabled', 'disabled')
          $(crtl.detentionPlaceDataWrapperTarget).addClass("d-none")
        }
      })
    }

    if (this.hasIsDetentionPlaceOperatorTarget) {
      $(this.isDetentionPlaceOperatorTargets).each(function() {
        $(this).on('change', function(e) {
          if ($(this).is(":checked")) {
            if ($(this).val() == "true") {
              $(crtl.detentionPlaceCodeInputTarget).removeAttr('disabled')
              $(crtl.holdingPlaceDataTarget).addClass("d-none").find('input, select').attr('disabled', 'disabled')
              $(crtl.detentionPlaceDataTarget).removeClass("d-none").find('input, select').removeAttr('disabled')
            } else if ($(this).val() == "false") {
              $(crtl.detentionPlaceCodeInputTarget).val("").attr('disabled', 'disabled')
              $(crtl.submitButtonTarget).removeAttr('disabled')
              $(crtl.detentionPlaceDataTarget).addClass("d-none").find('input, select').attr('disabled', 'disabled')
              $(crtl.holdingPlaceDataTarget).removeClass("d-none").find('input, select').removeAttr('disabled')
            }
          }
        })
      })      
    }

    if (this.hasStationedInBelgiumSwitchTarget) {
      $(this.stationedInBelgiumSwitchTargets).each(function() {
        $(this).on('change', function(e) {
          if ($(this).is(":checked")) {
            if ($(this).val() == "true") {
              $(crtl.insideBelgiumHoldingPlaceTarget).removeClass("d-none").find('input, select').removeAttr('disabled')
              $(crtl.noDetentionPlaceCodeInputTarget).removeAttr('disabled').trigger("change")
              $(crtl.outsideBelgiumHoldingPlaceTarget).addClass("d-none").find('input, select').attr('disabled', 'disabled')
            } else if ($(this).val() == "false") {
              $(crtl.insideBelgiumHoldingPlaceTarget).addClass("d-none").find('input, select').attr('disabled', 'disabled')
              $(crtl.noDetentionPlaceCodeInputTarget).removeAttr('disabled').trigger("change")
              $(crtl.outsideBelgiumHoldingPlaceTarget).removeClass("d-none").find('input, select').removeAttr('disabled')
            }
          }

          $(crtl.noDetentionPlaceCodeInputTarget).trigger("change")
        })
      })
    }

    $(this.stationedInBelgiumSwitchTargets).each(function() {
      if ($(this).is(":checked")) {
        $(this).trigger("change")
      }
    })
  }

  updateEmbryoTransfer(e) {
    let checked = $(e.currentTarget).val()

    if (checked) {
      $('#birth_registration_icsi_false').prop('checked', true);
    }
  }

  updateIcsi(e) {
    let checked = $(e.currentTarget).val()

    if (checked) {
      $('#birth_registration_embryo_transfer_false').prop('checked', true);
    }
  }

  showNoCouplingCardConfirmation() {
    $(this.noCouplingCardConfirmedInputTarget).val(false)
    $(this.noCouplingCardConfirmedTarget).addClass('d-none')
    $(this.noCouplingCardConfirmationTarget).removeClass('d-none')
  }

  confirmHasNoCouplingCard() {
    $(this.noCouplingCardConfirmedInputTarget).val(true)
    $(this.noCouplingCardConfirmationTarget).addClass('d-none')
    $(this.noCouplingCardConfirmedTarget).removeClass('d-none')
  }

  infirmHasNoCouplingCard() {
    $(this.noCouplingCardConfirmedInputTarget).val(false)
    $(this.noCouplingCardConfirmedTarget).addClass('d-none')
    $(this.noCouplingCardConfirmationTarget).addClass('d-none')
    $('input[name="birth_registration[coupling_card_selection]"').prop('checked', false);
  }

  showCardQuestions() {
    let studbook = $("[name='birth_registration[horse_database]']").val()

    $('label[for="birth_registration_coupling_card_selection_studbook"]').text(I18n.t("birth_registrations.card_questions.studbook", { "studbook": studbook }))
    $("#no_coupling_card_agreement").html(I18n.t("birth_registrations.general_conditions_html", { "studbook" : studbook }))

    $("#coupling_card_questions").toggleClass("d-none", (studbook == ""))
  }

  showCardSelection() {
    let checked = $("[name='birth_registration[coupling_card_selection]']:checked")

    $(".coupling-card-origin-choice").addClass("d-none").find("input, select").addClass("disabled").attr("disabled", "disabled");
    $('*[data-choice="' + checked.val() + "_data" + '"]').removeClass("d-none").find("input, select").removeClass("disabled").removeAttr("disabled");
  }

  showCardSelectionYear() {
    let checked = $("[name='birth_registration[coupling_card_selection_year]']:checked")

    $(".coupling-card-origin-choice-year").addClass("d-none").find("input, select").addClass("disabled").attr("disabled", "disabled");
    $('*[data-choice="' + checked.val() + "_data" + '"]').removeClass("d-none").find("input, select").removeClass("disabled").removeAttr("disabled");
  }

  _onInputChange(e) {
    let changedInput = e.target;
    let inputTag =changedInput.tagName
    let valueKey = $(changedInput).attr("name").replace(/\[/g, "_").replace(/\]/g, "");
    let value = ""
    switch (inputTag) {
      case "SELECT":
      case "select":
        if (valueKey == "birth_registration_breeder_data_national_number_country" ||
          valueKey == "birth_registration_co_breeder_data_national_number_country" ||
          valueKey == "birth_registration_owner_data_national_number_country" ||
          valueKey == "birth_registration_co_owner_data_national_number_country" ||
          valueKey == "birth_registration_breeder_data_company_country" ||
          valueKey == "birth_registration_co_breeder_data_company_country" ||
          valueKey == "birth_registration_owner_data_company_country" ||
          valueKey == "birth_registration_co_owner_data_company_country") {
          value = $(changedInput).val()
        } else {
          value = $(changedInput).find(":selected").text()
        }
        break;
      case "input":
      case "INPUT":
          if ($(changedInput).attr("type") == "checkbox" && $(changedInput).val() == "1") {
            value = $(changedInput).is(":checked")
          } else if ($(changedInput).attr("type") == "radio") {
            value = $(changedInput).siblings("label").text()
          } else {
            value = $(changedInput).val()
          }
        break
      default:
        value = $(changedInput).val()
    }
    if (valueKey.length > 0) {
      if ($(changedInput).is(":checked") || $(changedInput).attr("type") != 'radio') {
        this._updateInfo(valueKey, value, changedInput)
      }
    }
  }

  _updateInfo(valueKey, value, input) {
    let container = $('*[data-card-value-bind="'+valueKey+'"]');
    let valuesArr
      switch(valueKey) {
        case "birth_registration_mare_id":
          this._updateHorse("birth_registration_mare", input)
          break
        case "birth_registration_stalion_id":
          this._updateHorse("birth_registration_stalion", input)
          break
        case "birth_registration_breeder_data_phone":
        case "birth_registration_breeder_data_mobile":
        case "birth_registration_breeder_data_fax":
        case "birth_registration_breeder_data_email":
        case "birth_registration_owner_data_phone":
        case "birth_registration_owner_data_mobile":
        case "birth_registration_owner_data_fax":
        case "birth_registration_owner_data_email":
        case "birth_registration_co_breeder_data_phone":
        case "birth_registration_co_breeder_data_mobile":
        case "birth_registration_co_breeder_data_fax":
        case "birth_registration_co_breeder_data_email":
        case "birth_registration_co_owner_data_phone":
        case "birth_registration_co_owner_data_mobile":
        case "birth_registration_co_owner_data_fax":
        case "birth_registration_co_owner_data_email":
        case "birth_registration_holder_data_phone":
        case "birth_registration_holder_data_mobile":
        case "birth_registration_holder_data_fax":
        case "birth_registration_holder_data_email":
        case "birth_registration_holder_data_cbc_id":
          this._updateContactDetail(container, value);
          break
        case "birth_registration_breeder_data_addr1":
        case "birth_registration_breeder_data_number":
        case "birth_registration_breeder_data_box":
        case "birth_registration_breeder_data_zip":
        case "birth_registration_breeder_data_locality":
        case "birth_registration_breeder_data_country_code":
          this._updateAddress("birth_registration_breeder_data")
          break;
        case "birth_registration_owner_data_addr1":
        case "birth_registration_owner_data_number":
        case "birth_registration_owner_data_box":
        case "birth_registration_owner_data_zip":
        case "birth_registration_owner_data_locality":
        case "birth_registration_owner_data_country_code":
          this._updateAddress("birth_registration_owner_data")
          break;
        case "birth_registration_co_breeder_data_addr1":
        case "birth_registration_co_breeder_data_number":
        case "birth_registration_co_breeder_data_box":
        case "birth_registration_co_breeder_data_zip":
        case "birth_registration_co_breeder_data_locality":
        case "birth_registration_co_breeder_data_country_code":
          this._updateAddress("birth_registration_co_breeder_data")
          break;
        case "birth_registration_co_owner_data_addr1":
        case "birth_registration_co_owner_data_number":
        case "birth_registration_co_owner_data_box":
        case "birth_registration_co_owner_data_zip":
        case "birth_registration_co_owner_data_locality":
        case "birth_registration_co_owner_data_country_code":
          this._updateAddress("birth_registration_co_owner_data")
          break;
        case "birth_registration_holder_data_addr1":
        case "birth_registration_holder_data_number":
        case "birth_registration_holder_data_box":
        case "birth_registration_holder_data_zip":
        case "birth_registration_holder_data_locality":
        case "birth_registration_holder_data_country_code":
          this._updateAddress("birth_registration_holder_data")
          this._updateAddress("birth_registration_holding_place_data", "birth_registration_holder_data")
          break;
        case "birth_registration_holding_place_data_addr1":
        case "birth_registration_holding_place_data_number":
        case "birth_registration_holding_place_data_box":
        case "birth_registration_holding_place_data_zip":
        case "birth_registration_holding_place_data_locality":
        case "birth_registration_holding_place_data_country_code":
          this._updateAddress("birth_registration_holding_place_data")
          break;
        case "birth_registration_detention_place_code":
          this._updateDetentionPlaceCode(input);
          break;
        case "birth_registration_horse_stationed_to_first_holder":
          if($("#birth_registration_horse_stationed_to_first_holder_true").prop("checked")){
            this._updateAddress("birth_registration_holding_place_data", "birth_registration_holder_data")
          }else{
            this._updateAddress("birth_registration_holding_place_data")
          }
          break;
        case "birth_registration_vet_id":
          this._updateIdentifier(input);
          break;
        case "birth_registration_stalion_name":
        case "birth_registration_stalion_birth_date":
        case "birth_registration_stalion_father_name":
        case "birth_registration_stalion_mother_name":
        case "birth_registration_stalion_microchip":
        case "birth_registration_stalion_code":
          this._updateHorseManual("birth_registration_stalion")
          break;
        case "birth_registration_mare_name":
        case "birth_registration_mare_birth_date":
        case "birth_registration_mare_father_name":
        case "birth_registration_mare_mother_name":
        case "birth_registration_mare_microchip":
        case "birth_registration_mare_code":
          this._updateHorseManual("birth_registration_mare")
          break;
        case "birth_registration_stationed_in_belgium":
          if($("#birth_registration_stationed_in_belgium_true").prop("checked")){
            $('.birth_registration_confirm_not_stationed_in_belgium').addClass("d-none")
            $('.birth_registration_confirm_stationed_in_belgium').removeClass("d-none")
          }else{
            $('.birth_registration_confirm_stationed_in_belgium').addClass("d-none")
            $('.birth_registration_confirm_not_stationed_in_belgium').removeClass("d-none")
          }
          break;
        case "birth_registration_holder_data_asl":
        case "birth_registration_breeder_data_asl":
        case "birth_registration_co_breeder_data_asl":
        case "birth_registration_owner_data_asl":
        case "birth_registration_co_owner_data_asl":
          this._updateAsl(container, value)
          break;
        default:
          container.text(value);
      }
  }

  _updateDetentionPlaceCode(input){
    let dp = $(input).val()

    $('#birth_registration_holding_place_data_address').html(dp)
  }

  _updateIdentifier(input){
    if($(input).val() == ''){
      $('#identifier_card_body').html("")
    }else{
      $.ajax({
        type: "POST",
        url: "/veterinarians/show.js",
        data: "id=" + $(input).val() + "&partial_name=birth_registrations/identifier_card_body&into=identifier_card_body"
      })
    }
  }

  updateHorse(e){
    let horseKey = $(e.currentTarget).data("horseKey")
    let horseEncoding = $(e.currentTarget).data("horseEncoding")

    if(horseEncoding == "manual"){
      this._updateHorseManual(horseKey)
    }else{
      let input = (horseKey == "birth_registration_stalion") ? $('select[name="birth_registration[stalion_id]"]').get(0) : $('select[name="birth_registration[mare_id]"]').get(0)
      this._updateHorse(horseKey, input)
    }
  }

  _clearHorse(horseKey){
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_pedigree"]').html('');
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_name"]').html('');
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_code"]').html('');
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_microchip"]').html('');
  }

  _updateHorse(horseKey, input){
    this._clearHorse(horseKey)

    let valuesArr = $(input).find(":selected").text().split(" - ")

    if (valuesArr[1]) {
      $(this.element).find('*[data-card-value-bind="' + horseKey + '_code"]').html(valuesArr[1].split(" ")[0]);
    }

    $(this.element).find('*[data-card-value-bind="' + horseKey + '_name"]').html(valuesArr[0]);
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_pedigree"]').html($(input).siblings("small.text-muted").text());
  }

  _updateHorseManual(horseKey){
    this._clearHorse(horseKey)

    let fatherName = $('#' + horseKey + '_father_name').val()
    let motherName = $('#' + horseKey + '_mother_name').val()

    fatherName = fatherName!=''?(I18n.t("horses.by") + ' ' + fatherName) : ''
    motherName = motherName!=''?(I18n.t("horses.and") + ' ' + motherName) : ''

    let pedigree = [fatherName, motherName].filter(x => x).join(' ')

    $(this.element).find('*[data-card-value-bind="' + horseKey + '_pedigree"]').html(pedigree);
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_name"]').html($('#' + horseKey + '_name').val());
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_code"]').html($('#' + horseKey + '_code').val());
    $(this.element).find('*[data-card-value-bind="' + horseKey + '_microchip"]').html($('#' + horseKey + '_microchip').val());
  }

  _updateAddress(addressId, fromAddressId=null){

    let fromFieldId = addressId

    if(fromAddressId){
      fromFieldId = fromAddressId
    }

    let line1 = $('#' + fromFieldId + '_addr1:not(:disabled)').val()
    let number = $('#' + fromFieldId + '_number:not(:disabled)').val()
    let box = $('#' + fromFieldId + '_box:not(:disabled)').val()

    if(box != ''){
      box = I18n.t('contacts.data_fields.default.box').toLowerCase() + ' ' + box
    }

    let zip = $('#' + fromFieldId + '_zip:not(:disabled)').val()
    let locality_name = $('#' + fromFieldId + '_locality:not(:disabled)').val()

    let addressPartOne = [line1, number, box].filter(x => x).join(' ')
    let addressPartTwo = [zip, locality_name].filter(x => x).join(' ')

    let address = (addressPartOne!=''?('<span class="d-none d-md-inline">' + addressPartOne + ', </span>'):'') + addressPartTwo;

    $(this.element).find('#' + addressId + '_address').html(address);
  }

  _updateContactDetail(container, value){
    container.text(value);

    if(value == ""){
      container.parent(".list-group-item").addClass('d-none');
    }else{
      container.parent(".list-group-item").removeClass('d-none');
    }
  }

  _updateAsl(container, value){
    container.text(value);
    if(value == ""){
      container.parents(".list-group-item").first().addClass('d-none');
    }else{
      container.parents(".list-group-item").first().removeClass('d-none');
    }
  }

  _onRowRepeat(repeatEvent) {
    if (repeatEvent.target) {
      $(repeatEvent.target).find("input").each(function(i, el){
        if ($(el).hasClass("coupling-date")) {
          let dateDataGroup = $(".form-confirm .data-group.coupling-date").first()
          let newDateDataGroup = dateDataGroup.clone()
          newDateDataGroup.find(".data-value span").attr("data-card-value-bind", $(el).attr("id"))
          dateDataGroup.after(newDateDataGroup)
        }
      })
    }
  }

  _onRowRemove(repeatEvent) {
    if (repeatEvent.target) {
      let removedDateInput = $(repeatEvent.target).find("input.coupling-date");
      if (removedDateInput) {
        let removeDateData = $(".form-confirm .data-group.coupling-date span[data-card-value-bind="+removedDateInput.attr("id")+"]");
        $(removeDateData).remove()
      }
    }
  }

}
