// Entry point for the build script in your package.json

// https://gorails.com/episodes/how-to-use-jquery-with-esbuild
import "./src/jquery"
// Rails 7.2 : ujs deprecated
import "./src/rails-ujs"

import "@hotwired/stimulus"
import "./controllers"

import "./src/i18n"
import "./src/browser-update"
import "./src/custom-event-polyfill"
import "./src/navbar"
import "./src/initializer"

import 'daemonite-material/js/material.min'
import 'bootstrap'

import "@hotwired/turbo-rails"
